import type { RouteComponent } from "vue-router"

export const views: Record<
    PageRoute.LastDegreeRouteKey,
    RouteComponent | (() => Promise<{ default: RouteComponent }>)
> = {
    CRMApplicationManagement_detail: () =>
        import("./CRMApplicationManagement/detail/index.vue"),
    CRMApplicationManagement_list: () =>
        import("./CRMApplicationManagement/list/index.vue"),
    403: () => import("./_builtin/403/index.vue"),
    404: () => import("./_builtin/404/index.vue"),
    500: () => import("./_builtin/500/index.vue"),
    "constant-page": () => import("./_builtin/constant-page/index.vue"),
    marketplace: () => import("./_builtin/marketplace/list/index.vue"),
    "marketplace-detail": () =>
        import("./_builtin/marketplace/detail/index.vue"),
    mainlogin: () => import("./_builtin/mainlogin/index.vue"),
    "not-found": () => import("./_builtin/not-found/index.vue"),
    about: () => import("./about/index.vue"),
    dashboard_analysis: () => import("./dashboard/analysis/index.vue"),
    dashboard_workbench: () => import("./dashboard/workbench/index.vue"),
    exception_403: () => import("./exception/403/index.vue"),
    exception_404: () => import("./exception/404/index.vue"),
    exception_500: () => import("./exception/500/index.vue"),
    "function_tab-detail": () => import("./function/tab-detail/index.vue"),
    "function_tab-multi-detail": () =>
        import("./function/tab-multi-detail/index.vue"),
    function_tab: () => import("./function/tab/index.vue"),
    function_websocket: () => import("./function/websocket/index.vue"),
    management_auth: () => import("./management/auth/index.vue"),
    management_role: () => import("./management/role/index.vue"),
    management_route: () => import("./management/route/index.vue"),
    management_user: () => import("./management/user/index.vue"),
    marketplaceWorkOrder_list: () =>
        import("./marketplaceWorkOrder/list/index.vue"),
    order_detail: () => import("./order/detail/index.vue"),
    order_list: () => import("./order/list/index.vue"),
    package_list: () => import("./package/list/index.vue"),
    plugin_charts_antv: () => import("./plugin/charts/antv/index.vue"),
    plugin_charts_echarts: () => import("./plugin/charts/echarts/index.vue"),
    plugin_copy: () => import("./plugin/copy/index.vue"),
    plugin_editor_markdown: () => import("./plugin/editor/markdown/index.vue"),
    plugin_editor_quill: () => import("./plugin/editor/quill/index.vue"),
    plugin_icon: () => import("./plugin/icon/index.vue"),
    plugin_map: () => import("./plugin/map/index.vue"),
    plugin_print: () => import("./plugin/print/index.vue"),
    plugin_swiper: () => import("./plugin/swiper/index.vue"),
    plugin_video: () => import("./plugin/video/index.vue"),
    tenant_detail: () => import("./tenant/detail/index.vue"),
    tenant_list: () => import("./tenant/list/index.vue"),
    version_language: () => import("./version/language/index.vue"),
    version_list: () => import("./version/list/index.vue"),
}
