const pkg: Record<string, any> = {
    name: "marketplaceWorkOrder",
    path: "/app-marketplaceWorkOrder",
    component: "basic",
    meta: {
        title: "marketplaceWorkOrder",
        i18nTitle: "Routes.marketplaceWorkOrder.list",
        requiresAuth: true,
        keepAlive: true,
        icon: "fluent:book-information-24-regular",
        order: 2,
    },
    children: [
        {
            name: "marketplaceWorkOrder_list",
            path: "/marketplaceWorkOrder/list",
            component: "self",
            meta: {
                title: "marketplaceWorkOrder",
                i18nTitle: "Routes.marketplaceWorkOrder.list",
                requiresAuth: true,
                keepAlive: true,
                icon: "ic:round-tab",
            },
        },
    ],
}

export default pkg
